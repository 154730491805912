/// <reference path="triggers.ts" />

$(function () {
    $.nette.init();
    g.controller = new ScrollMagic.Controller();

    g.html = $("html");
    g.body = $("body");
    g.nav = $("nav");
    g.window = $(window);
    g.header = $('header');

    g.height = g.window.innerHeight();
    g.realHeight = window.innerHeight;
    g.width = g.window.innerWidth();

    g.gsap = new gsapObjects();
    //g.loader = new Loader();

    resize();

    let tempHeight = 0;
    let tempWidth = 0;

    var doit: any;
    window.onresize = function () {
        clearTimeout(doit);
        doit = setTimeout(resize, 100);
    };
    g.triggers = new Triggers();

    function resize() {
        // Get dimensions
        g.height = g.window.innerHeight();
        // Real height on safari iPhone - height is vh unit - innerHeight is 100% of window
        g.realHeight = window.innerHeight;
        g.width = g.html.outerWidth();

        // This skip event resize on iOS devices - if header was only scaled-out
        // if you need - you can delete this condition
        if (tempWidth == g.width && tempHeight == g.height) {
            return false;
        }

        tempWidth = g.width
        tempHeight = g.height

        // Detect hover events
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || g.width < 600)) {
            g.html.addClass("hover");
            g.hover = true;
        }
        else {
            g.html.removeClass("hover");
            g.hover = false;
        }

        // Destroy colection od scenes
        // TODO: implement ScrollMagic screene in resize function
        if (g.controller != null) {
            for (let scene of g.scene) {
                scene.destroy(true);
            }
            g.scene = [];
        }
        g.controller = new ScrollMagic.Controller();

        //Elements resize
        g.gsap.resize();
    }


    g.body.on('click', '[data-scroll]', function (e) {
        // maybe hamburger menu close
        e.preventDefault();
        var $this = $(this);
        var target = $($this.attr('data-scroll'));
        var element: any = $($this.attr('data-element'));
        var attrDelay = $this.attr('data-delay');
        var attrDuration = $this.attr('data-duration');
        var attrOffset = $this.attr('data-offset');

        var delay = 0;
        var duration = .8;
        var offset = -(g.nav.height());

        // if delay and duration ware set up
        if (typeof element !== typeof undefined) { element = window; }
        if (typeof attrDelay !== typeof undefined) { delay = parseInt(attrDelay); }
        if (typeof attrDuration !== typeof undefined) { duration = parseInt(attrDuration); }
        if (typeof attrOffset !== typeof undefined) { offset = parseInt(attrOffset); }

        if (g.debug) {
            if (!target.length) {
                console.error('Target element not found');
            }
        }

        var targetWithOffset = target.offset().top + offset

        TweenMax.to(element, duration, {
            delay: attrDelay,
            scrollTo: { y: targetWithOffset, autoKill: false },
            ease: Power3.easeInOut
        });
    });

    // -----------------------------
    // ------ debug on arrows  -----
    // -----------------------------
    //g.body.keydown(function (e) {
    //    switch (e.which) {
    //        case 37: // left
    //            break;
    //        case 38: // up
    //            break;
    //        case 39: // right
    //            break;
    //        case 40: // down
    //            break;
    //        default: return;
    //    }
    //});

    g.window.load(function () {
        //resize po nacteni obrazku
        window.setTimeout(function () {
            g.gsap.afterLoadInitialization();
            resize();
        }, 0);
    });

    $.nette.ext('form', {
        before: function () {
            //g.loader.startLoader();
        },
        success: function (payload) {
            console.log(payload);
            
            //Maybe this should be moved to "complete option", but do it carefully
            g.gsap.afterLoadInitialization();
            console.log(payload);

            if (payload.snippets.hasOwnProperty('snippet--unpaidregs') && g.gsap.elements['PopupControl_popup-unpaid']) {
                g.gsap.elements['PopupControl_popup-unpaid'].hide(1);
            }            

            // TODO: maybe refactor?
            if (payload.showPwdForm) {
                $('.change-pwd-form').show();
            }
            if (payload.hidePwdForm) {
                $('.change-pwd-status').text('Heslo bylo změněno.');
                $('.change-password').text('Změnit znovu');
                $('.change-password').show();
                $('.change-pwd-link').show();
            }

            if (payload.resizeOrderPopup || payload.referenceWizard) {
                const targetWithOffset = $('.popup-shown').offset().top;
                TweenMax.to(window, 0.6, {
                    scrollTo: { y: targetWithOffset, autoKill: false },
                    ease: Power3.easeInOut
                });
                console.log('resizing order popup');
                
            }
        },
        complete: function () {
            //g.loader.endLoader();
        }
    });

    $.nette.ext('selects', {
        //success: g.gsap.elements['Select2'].init
    });

    // Homepage Youtube player
    let tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    $('.play-video').show();

    if (navigator.userAgent.indexOf("MSIE") > 0) {
        $("input[type=file]").mousedown(function (event: Event) { $(this).trigger('click'); });
    }

    if (!Modernizr.svg) {
        $('img[src*="svg"]').attr('src', function () {
            return $(this).attr('src').replace('.svg', '.png');
        });
    }

    // touchswipe from @layout
    g.body.touchwipe({
        wipeLeft: function () { $(".nivo-lightbox-next").click(); },
        wipeRight: function () { $(".nivo-lightbox-prev").click(); },
        min_move_x: 20,
        min_move_y: 20,
        preventDefaultEvents: false
    })

    // Nivo lightbox initialization
    $('a.nivo').nivoLightbox();

    // Faq accordion init
    $('[data-accordion]').each(function () {
        g.faqAccordion = new AccordionControl($(this));
    });

    // check if you came from calendar
    const buyCourseId = g.getUrlParams('buy');
    if (buyCourseId) {
        const orderBtn = $(`[data-instance-id=${buyCourseId}]`);
        
        if (orderBtn) {
            const cleanUri = location.protocol + '//' + location.host + location.pathname + location.hash;
            window.history.replaceState({}, document.title, cleanUri);
            window.setTimeout(() => {
                orderBtn.eq(0).trigger('click');
            }, 400)
        }
    }
});

let YOUTUBE_player: any;
let YOUTUBE_vizual: any;
let YOUTUBE_player_ready = false;

function onYouTubeIframeAPIReady() {
    YOUTUBE_player = new YT.Player('youtube-placeholder', {
        width: '100%',
        height: '100%',
        videoId: '02pLRi0fHJM',
        events: {
            'onReady': onYouTubePlayerReady,
            'onStateChange': onYouTubePlayerStateChange
        }
    });
}

function onYouTubePlayerReady(event: Event) {
    $('body').on('click', '.play-video', function (e) {
        e.preventDefault();
        YOUTUBE_vizual = $(this).closest('.vizual');
        YOUTUBE_vizual.find('.centered').hide();
        YOUTUBE_vizual.find('.logo-container').hide();
        YOUTUBE_vizual.find('.more').hide();
        YOUTUBE_vizual.find('.youtube-wrapper').show();
        YOUTUBE_player.playVideo();
    });
    if (YOUTUBE_player_ready) {
        YOUTUBE_player.playVideo();
    }
    YOUTUBE_player_ready = true;
}

function onYouTubePlayerStateChange(event: any) {
    if (event.data == YT.PlayerState.ENDED) {
        YOUTUBE_vizual.find('.youtube-wrapper').hide();
        YOUTUBE_vizual.find('.centered').show();
        YOUTUBE_vizual.find('.logo-container').show();
        YOUTUBE_vizual.find('.more').show();
    }
}